<template>
    <div>
        <!--begin::customer-->
        <div class="form-group row">
            <div class="col-lg-12 mb-5">
                <label>{{$t('pos_session.shift')}}<span class="text-danger">*</span></label>
                <select name="shift" id="shift" v-model="data.shift_id" class="custom-select" :class="validation && validation.shift_id ? 'is-invalid' : ''">
                    <option v-for="row in shifts" :value="row.id" :key="row.id">{{row.name}}</option>
                </select>
                <span v-if="validation && validation.shift_id" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.shift_id[0] }}
                </span>
            </div>
        </div>
        <div class="form-group row">
            <table class="table">
                <thead>
                <tr>
                    <th>{{$t('pos_session.payment_method')}}</th>
                    <th>{{$t('pos_session.expected_amount')}}</th>
                    <th>{{$t('pos_session.received_amount')}}</th>
                    <th>{{$t('pos_session.difference')}}</th>
                    <th>{{$t('pos_session.received_type')}}</th>
                    <th>{{$t('pos_session.employee')}}/{{$t('pos_session.treasury')}}</th>
                </tr>
                </thead>
                <tbody>
                    <tr v-for="(row, index) in invoices_list" :key="index">
                        <td>{{row.payment_method_id == 1 ? payment_method : ''}}</td>
                        <td>{{row.expected_amount}}</td>
                        <td><input type="number" v-model="row.received_amount" @input="receivedAmount(index)" class="form-control"></td>
                        <td>{{row.difference}}</td>
                        <td>
                            <select v-model="row.type_type" class="custom-select">
                                <option v-for="row in received_types" :value="row.id" :key="row.id">{{row.title}}</option>
                            </select>
                        </td>
                        <td>
                            <select v-if="row.type_type == 1" v-model="row.type_id" class="custom-select">
                                <option v-for="row in employees" :value="row.id" :key="row.id">{{row.full_name}}</option>
                            </select>
                            <select v-if="row.type_type == 2" v-model="row.type_id" class="custom-select">
                                <option v-for="row in treasuries" :value="row.id" :key="row.id">{{row.name}}</option>
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
<!--        <div class="form-group row">-->
<!--            <div class="col-lg-6 mb-5">-->
<!--                <label>{{$t('pos_session.received_type')}}<span class="text-danger">*</span></label>-->
<!--                <select name="received_type" id="received_type" v-model="data.type_type" class="custom-select" :class="validation && validation.type_type ? 'is-invalid' : ''">-->
<!--                    <option v-for="row in received_types" :value="row.id" :key="row.id">{{row.title}}</option>-->
<!--                </select>-->
<!--                <span v-if="validation && validation.type_type" class="fv-plugins-message-container invalid-feedback">-->
<!--                    {{ validation.type_type[0] }}-->
<!--                </span>-->
<!--            </div>-->
<!--            <div class="col-lg-6 mb-5">-->
<!--                <label>{{$t('pos_session.employee')}}<span class="text-danger">*</span></label>-->
<!--                <select name="employee_id" id="employee_id" v-model="data.type_id" class="custom-select" :class="validation && validation.type_id ? 'is-invalid' : ''">-->
<!--                    <option v-for="row in employees" :value="row.id" :key="row.id">{{row.full_name}}</option>-->
<!--                </select>-->
<!--                <span v-if="validation && validation.type_id" class="fv-plugins-message-container invalid-feedback">-->
<!--                    {{ validation.type_id[0] }}-->
<!--                </span>-->
<!--            </div>-->
<!--        </div>-->

        <div class="card-footer pl-0 pr-0 pb-0">
            <div class="row">
                <div class="col-lg-6">
                    <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
                    <button type="reset" class="btn btn-secondary" @click="cancel">{{ $t('cancel') }}</button>
                </div>
            </div>
        </div>

        <!--end::customer-->
    </div>
</template>
<script>
    import ApiService from "@/core/services/api.service";

    export default {
        name: "form-pos-session-validate",
        props: {
            hideModal: {type: Function},
            handlingData: {type: Function},
            currentId: null,
            currentStatus: null,
            currentSessionId: null,


        },
        data() {
            return {
                mainRoute: 'pos/validate-session',
                mainRouteDependency: 'base/dependency',

                shifts: [],
                received_types: [],
                employees: [],
                treasuries: [],
                data: {
                    session_id: null,
                    shift_id: null,
                    // expected_amount: null,
                    // received_amount: null,
                    // payment_method_id: 1,
                    // type_type: null,
                    // type_id: null,
                },
                validation: null,
                difference: null,
                currency: null,
                payment_method: 'Cash payment',
                invoices_list:[],
            };
        },
        watch:{
            'data.shift_id': function (val) {
                if(val){
                    this.invoices_list.forEach((row, index)=>{
                        this.invoices_list[index].shift_id = val;
                    })
                }
            }
        },
        methods: {
            save() {
                if (!this.data.shift_id){
                    this.$warningMessage(this.$t('pos_session.please_select_shift'));
                    return false;
                }
                this.create();
            },
            cancel() {
                this.$emit('hide-modal');
                this.resetAfterSave();
                this.finishedSave();
            },
            finishedSave(su_object) {
                this.$emit('handling-data', su_object);
            },
            create() {
                let _data = this.invoices_list;
                _data = _data.map((row)=>{
                    delete row.difference
                    return row;
                });
                ApiService.post(this.mainRoute, {
                    ...this.data,
                    invoices_list: _data,
                }).then((response) => {
                    this.$successAlert(response.data.message);
                    this.cancel();
                }).catch(error => {
                    this.$errorAlert(error);
                });
            },
            getShifts() {
                ApiService.get(this.mainRouteDependency + "/pos_shift").then((response) => {
                    this.shifts = response.data.data;
                });
            },
            getReceivedType() {
                ApiService.get(this.mainRouteDependency + "/received_type").then((response) => {
                    this.received_types = response.data.data;
                });
            },
            getEmployees() {
                ApiService.get(this.mainRouteDependency + "/employees").then((response) => {
                    this.employees = response.data.data;
                });
            },
            getTreasuries() {
                ApiService.get(this.mainRouteDependency + "/treasuries").then((response) => {
                    this.treasuries = response.data.data;
                });
            },
            getPOSSessionInvoicesData() {
                ApiService.get(this.mainRouteDependency + "/invoice_by_session/"+this.currentSessionId).then((response) => {
                    response.data.data.map((row)=>{
                        this.invoices_list.push({
                            session_id: this.currentSessionId,
                            shift_id: null,
                            expected_amount: row.invoice_total,
                            received_amount: null,
                            payment_method_id: 1,
                            type_type: null,
                            type_id: null,
                            difference: null,
                        });
                    })

                });
            },
            resetAfterSave() {
                this.data.session_id = null;
                this.data.shift_id = null;
                this.invoices_list = [];
            },
            receivedAmount(index){
                this.invoices_list[index].difference = ((this.invoices_list[index].expected_amount ? parseFloat(this.invoices_list[index].expected_amount) : 0) - (this.invoices_list[index].received_amount ? parseFloat(this.invoices_list[index].received_amount) : 0)).toFixed(2);
            }
        },

        mounted() {
            this.data.session_id = this.currentSessionId;
            this.getShifts();
            this.getReceivedType();
            this.getEmployees();
            this.getTreasuries();
            this.getPOSSessionInvoicesData();
        },
    };
</script>